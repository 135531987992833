<template>
  <NavQuestion
    :heading="heading"
    :number="'1'"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in primaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'guardian_primary'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :show="show.personModal"
        :person="selectedPerson"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
      <IntroModal
        v-show="show.intro"
        :show="show.intro"
        @close="show.intro = false"
      ></IntroModal>
      <GuardianPartnerModal
        :show="show.partnerModal"
        @close="show.partnerModal = false"
      ></GuardianPartnerModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import IntroModal from '../IntroModal'
import GuardianPartnerModal from './Modals/PartnerModal'

export default {
  name: 'GuardiansPrimary',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad,
    IntroModal,
    GuardianPartnerModal
  },
  computed: {
    heading() {
      let string = 'Who would you like to name as the main guardians(s) for '
      if (
        this.children.length &&
        this.personalDetails.children === 'expecting'
      ) {
        return (string +=
          this.listPeople(this.children, ', ', true) +
          ' and your future child/children?')
      } else if (
        !this.children.length &&
        this.personalDetails.children === 'expecting'
      ) {
        return (string += 'your future child/children?')
      } else {
        return (string += this.listPeople(this.children, ' and ', true) + '?')
      }
    },
    subHeading() {
      return (
        ' You must appoint at least one person; although it is possible to have two or more Guardians. If your ' +
        this.childsOrChildrens(this.children) +
        ' ' +
        this.childOtherParent(this.personalDetails.sex)[0] +
        ' already has Parental Responsibility there is no need to name ' +
        this.childOtherParent(this.personalDetails.sex)[1] +
        ' as a guardian.'
      )
    },
    primaryPeople() {
      return this.$store.getters.people.filter(
        (person) => !person.guardian_secondary && !person.under18
      )
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    people() {
      return this.$store.getters.people
    },
    children() {
      return this.people.filter((person) => person.child)
    },
    forwardTo() {
      return '/guardians/secondary_option'
    },
    backTo() {
      return '/home'
    },
    isValidated() {
      if (this.people.filter((person) => person.guardian_primary).length)
        return true
      return false
    },
    partner() {
      return this.$store.getters.partner
    },
    checkAllChildCondition() {
      // all children in will are born to partner
      var validChildren = this.children.filter((child) =>
        child.child_born_to.includes(this.partner.full_name)
      )
      if (validChildren.length === this.children.length && this.children.length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false,
        intro: true,
        partnerModal: false
      }
    }
  },
  methods: {
    save(person) {
      if (
        person.partner &&
        !this.partner.guardian_primary &&
        this.checkAllChildCondition
      ) {
        this.show.partnerModal = true
      } else {
        this.savePerson(person)
      }
    },
    savePerson(person) {
      var copyPerson = Object.assign({}, person)
      copyPerson.guardian_primary = !person.guardian_primary
      this.$store.commit('personAdd', copyPerson)
    },

    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('guardian_primary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
