<template>
  <BaseModalSmall
    @close="close"
    v-show="show"
    :show="show"
    isValidated="true"
    @confirm="save"
    :noSlide="true"
  >
    <template v-slot:header>Information</template>
    <template v-slot:body>
      <div v-html="description"></div>

      <br /><br />
    </template>
    <template v-slot:button> Okay </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'GuardianPartnerModal.vue',
  props: ['show'],
  mixins: [willStringHelpers],
  components: {
    BaseModalSmall
  },
  data() {
    return {
      buttonText: 'Save'
    }
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    children() {
      return this.$store.getters.people.filter((person) => person.child)
    },
    description() {
      return `You have stated that ${this.partner.full_name} is the ${
        this.childParent(this.partner.gender)[0]
      } of ${this.listPeople(this.children)}, therefore ${this.heShe(
        this.partner.gender
      )} already has parental responsibility and does not also need to be named as a guardian.`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.primary {
  color: #e56781;
}
</style>
