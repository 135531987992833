<template>
  <BaseModalIntro
    v-if="showValue"
    :introTitle="'Guardians'"
    :showValue="showValue"
    :show="show"
    @close="$emit('close')"
    @doNotShowAgain="doNotShowAgain"
  >
    <template v-slot:contents>
      <h5>Overview</h5>

      <p>
        Any parent who has parental responsibility for their child may appoint
        one or more individuals to be the child's guardian. As you have stated
        that you have children under the age of
        {{ ageRequirement }}, it is important to appoint a guardian in your
        Will.<br /><br />
        The appointment of a guardian takes effect on death, provided that there
        is no surviving parent with parental responsibility. If there is a
        surviving parent with parental responsibility the appointment of the
        guardian takes effect only on the death of the surviving parent. Without
        a guardianship appointment a court would decide who should be given
        custody after your death.
      </p>

      <h5>Responsibilities</h5>

      <p>
        The role is essentially the same as that of a parent. A guardian has the
        legal authority to make decisions on behalf of your child with regard to
        their upbringing, schooling and anything else a parent would otherwise
        deal with.
      </p>

      <h5>Choices</h5>

      <p>
        You could appoint a single person to take the responsibility of being
        guardian, or two or three people could be appointed to share the
        responsibility. Additionally you have the option of naming 'backup'
        guardians to step in should those first named be unable or unwilling to
        take the role.
      </p>
    </template>
  </BaseModalIntro>
</template>

<script>
import BaseModalIntro from '@/common/ui/BaseModalIntro'

export default {
  name: 'GuardiansIntroModal',
  props: ['show'],
  components: {
    BaseModalIntro
  },
  computed: {
    showValue() {
      return this.$store.getters.showIntroModals.guardians
    },
    ageRequirement() {
      if (this.$store.getters.personalDetails.country === 'Scotland')
        return '16'
      return '18'
    }
  },
  methods: {
    doNotShowAgain() {
      this.$store.commit('showIntroModals', { guardians: false })
    }
  }
}
</script>

<style></style>
